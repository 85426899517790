import { Component } from '@angular/core';
import { Routes } from '@angular/router';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { BaMenuService } from '../core';
import { BackendService } from '../core/services';
import { LoginDialog } from '../core/components';
import { PORTAL_MENU } from './portal.menu';

@Component({
    selector: 'portal',
    templateUrl: './portal.html'
})
export class Portal {

    loginDialog: MatDialogRef<LoginDialog>;
    errorSubscription: Subscription;

    constructor(
        private _menuService: BaMenuService,
        private backend: BackendService,
        private dialog: MatDialog,
    ) {
        this.errorSubscription = this.backend.error.subscribe((error) => {
            if(error.status != 401) {
                return;
            }
            if(this.loginDialog){
                return;
            }
            this.loginDialog = this.dialog.open(LoginDialog, {disableClose: true});
            this.loginDialog.afterClosed().subscribe(() => {
                this.loginDialog = undefined;
            });
        });
    }

    ngOnInit() {
        this._menuService.updateMenuByRoutes(<Routes>PORTAL_MENU);
    }

    ngOnDestroy() {
        if(this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }
}
