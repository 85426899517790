import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { AuthService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(map((response: boolean) => {
            if(response == false) {
                if(state.url) this.auth.setRedirectPath(state.url);
                this.router.navigate(['/login']);
            }
            return response;
        }));
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}