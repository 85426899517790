<div class="user-toolbar-inner" [ngClass]="{'is-menu-collapsed': isMenuCollapsed }">
    <div class="user-account">
        <div [matMenuTriggerFor]="accountMenu" class="account-menu-trigger">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            <div class="user-info">
                <span class="name">{{userName}}</span>
                <span class="role">{{userRole}}</span>
            </div>
        </div>
        <mat-menu #accountMenu="matMenu" [xPosition]="'before'" class="user-menu">
            <button mat-menu-item (click)="logout()" translate>login.sign_out</button>
        </mat-menu>
    </div>
    <div class="supplier-switcher" *ngIf="['supplier'] | role | async">
        <div [matMenuTriggerFor]="supplierMenu" class="supplier-menu-trigger">
            <i class="fa fa-truck" aria-hidden="true"></i>
            <div class="name"><span>{{currentSupplier?.name}}</span></div>
        </div>
        <mat-menu #supplierMenu="matMenu" [xPosition]="'before'" class="supplier-menu">
            <button mat-menu-item *ngFor="let supplier of suppliers"
                [ngClass]="{selected: supplier.id === currentSupplier?.id}"
                (click)="selectSupplier(supplier)">
                {{ supplier.name }}
            </button>
        </mat-menu>
    </div>
    <div class="market-switcher" *ngIf="['administrator', 'buyer'] | role | async">
        <div [matMenuTriggerFor]="marketMenu" class="market-menu-trigger">
            <i class="fa fa-globe" aria-hidden="true"></i>
            <div class="name"><span>{{currentMarket?.name}}</span></div>
        </div>
        <mat-menu #marketMenu="matMenu" [xPosition]="'before'" class="market-menu">
            <button mat-menu-item *ngFor="let market of markets"
                [ngClass]="{selected: market.id === currentMarket?.id}"
                (click)="selectMarket(market)">
                {{ market.name }}
            </button>
        </mat-menu>
    </div>
</div>
