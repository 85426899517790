import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
    selector: 'nc-input',
    template: `
        <div class="form-group">
            <mat-form-field>
                <input matInput placeholder="{{ label }}" [formControl]="control">
                <mat-error><error-message [forControl]="control"></error-message></mat-error>
            </mat-form-field>
        </div>`,
})
export class NcInput {

    @Input()
    control: FormControl;

    @Input()
    label: any;

}
