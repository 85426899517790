import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { BackendService } from "../../services";

@Component({
    selector: 'preview-dialog',
    templateUrl: './previewDialog.html',
    styleUrls: ['./previewDialog.scss'],
})
export class PreviewDialog {

    displayViewPitStopReportButton: boolean;

    constructor(
        public dialogRef: MatDialogRef<PreviewDialog>,
        public backend: BackendService,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
    ) {
        this.displayViewPitStopReportButton = dialogData.viewPitStopReport;
    }

    approve() {
        if(this.dialogData.onApprove) {
            this.dialogData.onApprove();
            this.dialogRef.close();
        }
    }

    viewPitStopReport() {
        let win = window.open(this.backend.urlTo(this.dialogData.part.report), '_blank');
        win.focus();
    }

}
