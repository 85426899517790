import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from '../config/local.config.js';

if (environment.production) {
    enableProdMode();
}

window['backendUrl'] = environment?.envVar?.backendUrl;
window['socketUrl'] = environment?.envVar?.socketUrl;

Sentry.init({
    dsn: environment.dsn,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
    ],
    // TODO: Add release (Currently running commit ID)
    environment: environment.env,

    // Global sample rate
    sampleRate: getSampleRate(environment.env),
    // sessions where errors occur.
    replaysOnErrorSampleRate: 0.1,

    // Performance Monitoring
    tracesSampleRate: getTracesSampleRate(environment.env),
    // Session Replay
    replaysSessionSampleRate: 0,
});

function getSampleRate(env: string): number {
    return ('prod' === env || 'stage' === env) ? 0.1 : 0;
}

function getTracesSampleRate(env: string): number {
    return ('prod' === env || 'stage' === env) ? 0.05 : 0;
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
