import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, Observer, Subject } from 'rxjs';
import { catchError, flatMap, map, publishLast, refCount, tap } from 'rxjs/operators';
import { ServerDataSource } from 'ng2-smart-table';
import * as _ from 'lodash';

import { IAppConfig, IBat } from '../interfaces';
import { objectToFormData } from '../functions';
import { IOrder, ISupplier } from "../interfaces";
import { GlobalState } from '../../global.state';

// ng2-smart-table
export class MyServerDataSource extends ServerDataSource {

    //private firstLoad: number = 0;
    public initialLoadComplete: Subject<boolean> = new Subject();

    // Prevent multiple requests
    // getElements(): Promise<any> {
    //     if(this.firstLoad == 0) {
    //         this.firstLoad++;
    //         return super.getElements();
    //     } else if((this.filterConf.filters) && (this.filterConf.filters.length >= this.firstLoad)) {
    //         this.firstLoad++;
    //         return Promise.resolve(this.data);
    //     } else {
    //         return super.getElements();
    //     }
    // }

    // Load event
    protected override requestElements(): Observable<any> {
        let httpParams = this.createRequesParams();

        return this.http.get(this.conf.endPoint, { params: httpParams, observe: 'response', withCredentials: true }).pipe(
            tap(() => {
                this.initialLoadComplete.next(true);
                this.initialLoadComplete.complete();
            }),
            catchError((error) => {
                this.initialLoadComplete.error(error);
                return throwError(error);
            }),
        );
    }

}

@Injectable()
export class BackendService {

    private address: string = window['backendUrl'] || 'http://localhost:86/';
    private config: Observable<IAppConfig>;
    public senderSessionId: string;
    public error: EventEmitter<any> = new EventEmitter();

    /**
     *
     * @param http
     * @param state
     */
    constructor(
        private http: HttpClient,
        private state: GlobalState,
    ) {
        // Check if user is logged in every 15 seconds

        this.startLoginCheckInterval();
    }

    private startLoginCheckInterval() {
        let isChecking: boolean = false;
        let lastCheck: number = +new Date();

        setInterval(() => {
            if (isChecking || +new Date() - lastCheck < 30 * 1000) { // if last check is less than X milliseconds ago
                return;
            }

            isChecking = true;
            this.getConfig().subscribe((config: IAppConfig) => {
                lastCheck = +new Date();
                isChecking = false;
                if(config.user === null) {
                    this.error.emit({status: 401});
                }
            });
        }, 500);
    }

    /**
     * Http request options
     * @param token
     * @param multipart
     * @returns {RequestOptionsArgs}
     */
    private getRequestOptions(token?: string, multipart?: boolean) {
        let headers = new HttpHeaders();
        if (this.senderSessionId) {
            headers = headers.append('X-SENDER-SESSION-ID', this.senderSessionId);
        }
        if(token) {
            headers = headers.append('X-CSRF-TOKEN', token);
        }
        if(multipart) {
            headers = headers.append('enctype', 'multipart/form-data');
        }
        return { headers, withCredentials: true };
    }

    /**
     * Method used for waiting for the next successful login attempt
     * @returns {Observable<boolean>}
     */
    private waitNextLogin(): Observable<boolean> {
        return Observable.create((observer: Observer<any>) => {
            let subscription = this.state.loggedInChange.subscribe(response => {
                if(response === true) {
                    subscription.unsubscribe();
                    observer.next(response);
                    observer.complete();
                }
            });
        });
    }

    /**
     * Http error handler
     * @param error
     * @param source
     * @returns {Observable<any>}
     */
    private handleError = (error: HttpErrorResponse, source: Observable<any>): Observable<any> => {
        this.error.emit(error);
        if(error.status == 401) {
            // Unauthorized: Wait until user logs in and retry.
            return this.waitNextLogin().pipe(flatMap(() => source));
        } else {
            return throwError(error);
        }
    }

    /**
     * Send a GET request to the backend.
     * @param uri
     * @returns {Observable<any>}
     */
    public get(uri: string): Observable<any> {
        return this.http.get(this.address + uri, this.getRequestOptions()).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param uri
     * @returns {MyServerDataSource}
     */
    public dataSource(uri: string): MyServerDataSource {
        return new MyServerDataSource(this.http, {
            endPoint: this.address + uri,
            pagerPageKey: 'page',
            pagerLimitKey: 'size',
            sortFieldKey: 'sort',
            sortDirKey: 'order',
        });
    }

    /**
     * Send a POST request to the backend.
     * @param uri
     * @param data
     * @param multipart
     * @returns {Observable<any>}
     */
    public post(uri: string, data?: any, multipart?: boolean): Observable<any> {
        if(multipart) {
            data = objectToFormData(data);
        }
        return this.getConfigFromCache().pipe(
            flatMap((config: IAppConfig) => {
                return this.http.post(this.address + uri, data, this.getRequestOptions(config.csrfToken, multipart));
            }),
            catchError(this.handleError),
        );
    }

    /**
     * Send a PUT request to the backend.
     * @param uri
     * @param data
     * @param multipart
     * @returns {Observable<any>}
     */
    public put(uri: string, data: any, multipart?: boolean): Observable<any> {
        if(multipart) {
            data = objectToFormData(data);
        }
        return this.getConfigFromCache().pipe(
            flatMap((config: IAppConfig) => {
                return this.http.put(this.address + uri, data, this.getRequestOptions(config.csrfToken, multipart));
            }),
            catchError(this.handleError),
        );
    }

    /**
     * Send a POST request with multipart data to the backend.
     * @param uri
     * @param data
     * @returns {Observable<any>}
     */
    public upload(uri: string, data?: any): Observable<any> {
        data = objectToFormData(data);
        return this.getConfigFromCache().pipe(
            flatMap((config: IAppConfig) => {
                return Observable.create((observer: Observer<any>) => {
                    let xhr: XMLHttpRequest = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                observer.next({
                                    progress: 100,
                                    data: JSON.parse(xhr.response),
                                });
                                observer.complete();
                            } else {
                                observer.error(xhr);
                            }
                        }
                    };
                    xhr.upload.onprogress = (event) => {
                        observer.next({
                            progress: Math.round(event.loaded / event.total * 100),
                        });
                    };
                    xhr.open('POST', this.address + uri, true);
                    xhr.setRequestHeader('X-CSRF-TOKEN', config.csrfToken);
                    xhr.setRequestHeader('enctype', 'multipart/form-data');
                    xhr.withCredentials = true;
                    xhr.send(data);
                });
            }),
            catchError(this.handleError),
        );
    }

    /**
     * Send a POST request with multipart data to the backend.
     * @param url
     * @param data
     * @returns {Observable<any>}
     */
    public uploadRemote(url: string, data?: any): Observable<any> {
        return this.getConfigFromCache().pipe(
            flatMap((config: IAppConfig) => {
                return Observable.create((observer: Observer<any>) => {
                    let xhr: XMLHttpRequest = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                observer.next({
                                    progress: 100,
                                    finished: true,
                                });
                                observer.complete();
                            } else {
                                observer.error(xhr);
                            }
                        }
                    };
                    xhr.upload.onprogress = (event) => {
                        observer.next({
                            progress: Math.round(event.loaded / event.total * 100),
                            finished: false,
                        });
                    };
                    xhr.open('PUT', url, true);
                    xhr.responseType = "text";
                    xhr.overrideMimeType("application/pdf");
                    xhr.setRequestHeader("Content-Type", "application/pdf");

                    xhr.send(data);
                });
            }),
            catchError(this.handleError),
        );
    }

    public download(uri: string): void  {
        this.getConfigFromCache()
        .subscribe((config: IAppConfig) => {
            window.open(this.address + uri);
        });
    }

    /**
     * Send a DELETE request to the backend.
     * @param uri
     * @returns {Observable<any>}
     */
    public delete(uri: string): Observable<any> {
        return this.getConfigFromCache().pipe(
            flatMap((config: IAppConfig) => {
                return this.http.delete(this.address + uri, this.getRequestOptions(config.csrfToken));
            }),
            catchError(this.handleError),
        );
    }

    /**
     * Get the config from backend.
     * @returns {Observable<IAppConfig>}
     */
    public getConfig() {
        return this.get('config').pipe(map((response: any) => response.config));
    }

    /**
     * Get the config from cache.
     * @param refresh
     * @returns {Observable<IAppConfig>}
     */
    public getConfigFromCache(refresh?: boolean): Observable<IAppConfig> {
        if(!this.config || refresh) {
            this.config = this.getConfig().pipe(
                publishLast(),
                refCount(),
            );
        }
        return this.config;
    }

    /**
     * Reset the config.
     */
    public resetConfig(): void {
        this.config = undefined;
    }

    /**
     * Inject the config.
     * @param observable
     */
    public injectConfig(observable: Observable<IAppConfig>): void {
        this.config = observable;
    }

    /**
     *
     * @param uri
     * @returns {string}
     */
    public urlTo(uri: string) {
        if (!uri) {
            return null;
        }
        return this.address + uri.replace(/^\/+/g, '');
    }
}

@Injectable()
export class UserService {

    constructor(private backend: BackendService) {}

    public getAll(){
        return this.backend.get('users');
    }

    public create(user){
        return this.backend.post('users/create', user);
    }

    public update(user) {
        return this.backend.put('users/update', user);
    }

    public delete(id) {
        return this.backend.delete('users/delete?id=' + id);
    }
}

@Injectable ()
export class UserGroupService {

    constructor(private backend: BackendService) {}

    public getAll () {
        return this.backend.get('user-groups');
    }

    public create(product) {
        return this.backend.post('user-groups/create', product);
    }

    public update(product) {
        return this.backend.put('user-groups/update', product);
    }

    public delete(id) {
        return this.backend.delete('user-groups/delete?id=' + id);
    }

    public getAllPermissions () {
        return this.backend.get('permissions');
    }
}

@Injectable()
export class SupplierService {

    constructor(private backend: BackendService) {}

    public getAll() {
        return this.backend.get('suppliers');
    }

    public getAllWithPriceListInfo() {
        return this.backend.get('suppliers/with-price-list-info');
    }

    public getByMarket(market) {
        return this.backend.get('suppliers/by-market/' + market);
    }

    public getDataSource() {
        return this.backend.dataSource('suppliers');
    }

    public create(supplier) {
        return this.backend.post('suppliers/create', supplier);
    }

    public update(supplier) {
        return this.backend.put('suppliers/update', supplier);
    }

    public delete(id) {
        return this.backend.delete('suppliers/delete?id=' + id);
    }
}

@Injectable()
export class ProductService {

    constructor(private backend: BackendService) {}

    public getAll(country: string = null) {
        if (country) {
            return this.backend.get('products/by-market/' + country);
        }
        return this.backend.get('products');
    }

    public getByMarket(market) {
        return this.backend.get('products/by-market/' + market);
    }

    public getDataSource() {
        return this.backend.dataSource('products');
    }

    public create(product) {
        return this.backend.post('products/create', product);
    }

    public update(product) {
        return this.backend.put('products/update', product);
    }

    public delete(id) {
        return this.backend.delete('products/delete?id=' + id);
    }

    public import(data) {
        return this.backend.post('product-import', data, true);
    }

    public export() {
        let uri = 'product-export';
        let win = window.open(this.backend.urlTo(uri), '_blank');
        win.focus();
    }
}

@Injectable()
export class PriceListService {

    constructor(private backend: BackendService) {}

    public getLatest(supplierId) {
        return this.backend.get('price-lists/' + supplierId + '/latest');
    }

    public getLatestApproved(supplierId) {
        return this.backend.get('price-lists/' + supplierId + '/latest-approved');
    }

    public downloadLatestApproved(supplierId) {
        return this.backend.download('price-lists/' + supplierId + '/download-latest-approved');
    }

    public downloadLatest(supplierId) {
        return this.backend.download('price-lists/' + supplierId + '/download-latest');
    }

    public create(priceList) {
        return this.backend.post('price-lists/create', priceList);
    }

    public update(priceList) {
        return this.backend.put('price-lists/update', priceList);
    }

    public import(supplierId, priceList) {
        return this.backend.post('price-list-import/' + supplierId, priceList, true);
    }

    public delete(priceListId) {
        return this.backend.delete('price-lists/delete?id=' + priceListId);
    }
}

@Injectable()
export class PitStopProfileService {

    constructor(private backend: BackendService) {}

    public getByMarket(country: string) {
        return this.backend.get('pit-stop-profile/by-market/' + country);
    }

    public getBySupplier(supplierId: string) {
        return this.backend.get('pit-stop-profile/by-supplier/' + supplierId);
    }

    public create(pitStopProfileUpload) {
        return this.backend.post('pit-stop-profile/create', pitStopProfileUpload, true);
    }

    public update(pitStopProfileUpload) {
        return this.backend.put('pit-stop-profile/update', pitStopProfileUpload, true);
    }

    public delete(id) {
        return this.backend.delete('pit-stop-profile/delete?id=' + id);
    }
}


@Injectable()
export class OrderService {

    constructor(private backend: BackendService) {}

    public getAll() {
        return this.backend.get('orders');
    }

    public getByMarket(country: string) {
        return this.backend.get('orders/by-market/' + country);
    }

    public searchByMarket(country: string, filters: any, page: number) {
        return this.backend.post('orders/search-by-market/' + country, {filters: filters, page: page});
    }

    public searchBySupplier(supplierId: string, filters: any, page: number) {
        return this.backend.post('orders/search-by-supplier/' + supplierId, {filters: filters, page: page});
    }

    public create(order) {
        return this.backend.post('orders/create', order);
    }

    public update(order) {
        return this.backend.put('orders/update', order);
    }

    public updateExtraCostStatus(order) {
        return this.backend.put('orders/update-extra-cost-status', order);
    }

    public cancel(order) {
        return this.backend.put('orders/cancel', order);
    }

    public confirmCancel(order) {
        return this.backend.put('orders/confirm-cancel', order);
    }

    public updateSubset(order: IOrder, keys: string[]) {
        return this.backend.put('orders/update', _.pick(order, keys));
    }

    public rollBackStatus(order: IOrder): Observable<any> {
        return this.backend.put('orders/roll-back-status', order);
    }

    public updateVisual(orderId, visual) {
        return this.backend.put('orders/' + orderId + '/visuals/update', visual);
    }

    public createVisual(orderId, visual) {
        return this.backend.post('orders/' + orderId + '/visuals/create', visual);
    }

    public deleteVisual(orderId, visual) {
        return this.backend.delete('orders/' + orderId + '/visuals/delete?uuid='+visual.uuid);
    }

    public updatePart(orderId, part) {
        return this.backend.put('orders/' + orderId + '/visuals/parts/update', part);
    }

    public upload(orderId, file) {
        return this.backend.upload('orders/' + orderId + '/visuals/parts/upload', file);
    }

    public signedUploadUrl(orderId: string, data: { uuid: string; fileName: string }) {
        return this.backend.post('orders/' + orderId + '/visuals/parts/signed-upload-url', data);
    }

    public signedUploadFinished(orderId: string, uuid: string) {
        return this.backend.post('orders/' + orderId + '/visuals/parts/signed-upload-finished', {uuid: uuid});
    }

    public uploadS3(url: string, file) {
        return this.backend.uploadRemote(url, file);
    }

    public signedUploadUrlBat(orderId: string, data: { uuid: string; fileName: string }) {
        return this.backend.post('orders/' + orderId + '/visuals/parts/bat/signed-upload-url', data);
    }

    public signedUploadFinishedBat(orderId: string, uuid: string) {
        return this.backend.post('orders/' + orderId + '/visuals/parts/bat/signed-upload-finished', { uuid });
    }

    public createBat(orderId: string, data: { uuid: string; fileUrl: string, status: string }) {
        return this.backend.post('orders/' + orderId + '/visuals/parts/bat/create', data);
    }

    public updateBat(orderId: string, data: { uuid: string; version: number, status: string }) {
        return this.backend.post('orders/' + orderId + '/visuals/parts/bat/update', data);
    }

    public getBySupplier(supplierId: string) {
        return this.backend.get('orders/by-supplier/' + supplierId + '?status=supplier-chosen,supplier-accepted,ready-for-production,in-production,delivered');
    }

    public updateAsSupplier(orderId: string, data: any) {
        return this.backend.put('orders/update-as-supplier', Object.assign({}, {id: orderId}, data));
    }

    public deny(orderId: string) {
        return this.backend.put('orders/deny', {id: orderId});
    }
    public getChat(orderId) {
        return this.backend.get('order-chat/' + orderId);
    }

    public createChatMessage(orderId, message) {
        return this.backend.post('order-chat/' + orderId + '/create-message/', message);
    }

    public exportByYear({country, year}) {
        let uri = `order-export/${country}/${year} `;
        let win = window.open(this.backend.urlTo(uri), '_blank');
        win.focus();
    }

    public exportByMonth({country, year, month}) {
        let uri = `order-export/${country}/${year}/${month} `;
        let win = window.open(this.backend.urlTo(uri), '_blank');
        win.focus();
    }
}

@Injectable()
export class OrderApproveVisualsService {

    constructor(private backend: BackendService) {}

    public getByToken(token): Observable<any> {
        return this.backend.get('orders/approve-visuals/' + token);
    }

    public approve(token: string, partUuid: string) {
        return this.backend.put('orders/approve-visuals/' + token + '/approve', {uuid: partUuid});
    }

    public updateBatStatus(token: string, partUuid: string, version: number, status: IBat['status'] ) {
        return this.backend.put('orders/approve-visuals/' + token + '/bat/status', { uuid: partUuid, version, status });
    }
}

@Injectable()
export class MarketService {

    constructor(private backend: BackendService) {}

    public getAll() {
        return this.backend.get('markets');
    }

}

@Injectable()
export class AddressService {

    constructor(private backend: BackendService) {}

    public getAll() {
        return this.backend.get('address');
    }

    public getByMarket(country) {
        return this.backend.get('address/by-market/' + country);
    }

    public create(address) {
        return this.backend.post('address/create', address);
    }

    public update(address) {
        return this.backend.put('address/update', address);
    }

    public delete(addressId) {
        return this.backend.delete('address/delete?id=' + addressId);
    }

    public import(data) {
        return this.backend.post('address-import', data, true);
    }

}

@Injectable()
export class AuditLogService {

    constructor(private backend: BackendService) {}

    public getDataSource() {
        return this.backend.dataSource('audit-log');
    }

}
