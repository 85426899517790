<ng-container *ngIf="!featureToggle('weekNumbersEnabled')">
    <mat-form-field>
        <input
            matInput
            [ngxMatDatetimePicker]="picker"
            [placeholder]="placeholder"
            [value]="dateTimeString"
            [min]="minDate"
            [max]="maxDate"
            [disabled]="disabled"
            (blur)="handleBlur($event)"
            (dateChange)="handleChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [stepMinute]="5">
            <ng-template>{{'general.ok' | translate}}</ng-template>
        </ngx-mat-datetime-picker>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="featureToggle('weekNumbersEnabled')">
    <mat-form-field>
        <input
            matInput
            [placeholder]="placeholder"
            [value]="dateTimeString"
            [disabled]="disabled"
            (blur)="handleBlur($event)">
        <button
            class="calendar-button"
            type="button"
            mat-icon-button
            matSuffix
            [matMenuTriggerFor]="menu">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
        </button>
    </mat-form-field>
</ng-container>

<mat-menu #menu="matMenu" xPosition="before" class="disable-padding">
    <div (click)="handleMenuClick($event)">
        <nc-datetime-picker-sc
            #dateTimePickerSC
            [value]="dateTimeString"
            [minDate]="minDate"
            [maxDate]="maxDate"
            (onChange)="handleChangeSC($event)">
        </nc-datetime-picker-sc>
    </div>
</mat-menu>
